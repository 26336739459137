import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../shared/auth-context';
import { useHttpClient } from '../../shared/http-hook';

import { Row, Col, Button, Modal, Form, Tabs, Tab } from 'react-bootstrap';
import LoadingSpinner from '../LoadingSpinner';

import AOS from "aos";
import "aos/dist/aos.css";

const DashboardProfile = () => {
    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();
    const [userData, setUserData] = useState();
    const [loading, setLoading] = useState(false);

    // const
    const [error, setError] = useState();
    const [username, setUsername] = useState();
    const [age, setAge] = useState();
    const [about, setAbout] = useState();
    const [image, setImage] = useState();

    // username
    const [usernameshow, usernameSetShow] = useState(false);
    const usernamehandleClose = () => usernameSetShow(false);
    const usernameHandleShow = () => usernameSetShow(true);

    const editUsername = async (event) => {
        event.preventDefault();

        if(!username){
            setError('Please check your username!');
            return;
        }

        try {
            const getuser = localStorage.getItem('user');
            const response = JSON.parse(getuser);

            response.username = username;

            localStorage.setItem('user', JSON.stringify(response))
            
            fetchUser()
            usernamehandleClose()
            setError()
        } catch (err) {
            console.log(err)
        }
    }
    //

    // age
    const [ageshow, ageSetShow] = useState(false);
    const agehandleClose = () => ageSetShow(false);
    const ageHandleShow = () => ageSetShow(true);

    const editAge = async (event) => {
        event.preventDefault();

        if(!age || age < 18){
            setError('Please check your age! You must be 18 or older!');
            return;
        }

        try {
            const getuser = localStorage.getItem('user');
            const response = JSON.parse(getuser);

            response.years = age;

            localStorage.setItem('user', JSON.stringify(response))
            
            fetchUser()
            agehandleClose()
            setError()
        } catch (err) {
            console.log(err)
        }
    }
    //

    // about
    const [aboutshow, aboutSetShow] = useState(false);
    const abouthandleClose = () => aboutSetShow(false);
    const aboutHandleShow = () => aboutSetShow(true);

    const editAbout = async (event) => {
        event.preventDefault();

        if(!about || about.length < 5){
            setError('Please check your description! Must contain more than 5 characters!');
            return;
        }

        try {
            const getuser = await localStorage.getItem('user');
            const response = JSON.parse(getuser);

            response.about = about;

            await localStorage.setItem('user', JSON.stringify(response))
            
            fetchUser()
            abouthandleClose()
            setError()
        } catch (err) {
            console.log(err)
        }
    }
    //

    // image
    const [imageshow, imageSetShow] = useState(false);
    const imagehandleClose = () => imageSetShow(false);
    const imageHandleShow = () => imageSetShow(true);

    const editImage = async (event) => {
        event.preventDefault();
        const imgPath = document.querySelector('#image').files[0];
        const reader = new FileReader();
        
        try {
            reader.addEventListener("load", function () {
                const getuser =  localStorage.getItem('user');
                const response = JSON.parse(getuser);
    
                response.image = reader.result;
                setImage(reader.result)
    
                localStorage.setItem('user', JSON.stringify(response))
            }, false);            
            
            if (imgPath) {
                reader.readAsDataURL(imgPath);
            }
            
            fetchUser()
            imagehandleClose()
            setError()
        } catch (err) {
            console.log(err)
        }
    }

    //
    const fetchUser = async () => {
        setLoading(true)

        try {
            const getuser = localStorage.getItem('user');
            const response = JSON.parse(getuser)
            setUserData(response);
            
            //
            setUsername(response.username)
            setAge(response.years)
            setAbout(response.about);
            setImage(response.image);

            setTimeout(function(){
                setLoading(false)
            }, 2000);
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const local = JSON.parse(localStorage.getItem('user'))
    const [profileV, setProfileV] = useState();
    const getViews = async () => {
        try {
            const response = await sendRequest('https://gorillamediallc.com/bangbase/api/getusersitems');
            const filter = response.user_items.filter((item) => item.type === "visitors" && item.member === local.username).slice(-6)
            setProfileV(filter.reverse())
        } catch (err) {}
    }

    useEffect(() => {
        getViews()
    }, []);


    // get comments
    const [comments, setComments] = useState([]);

    const getComments = async () => {
        const response = await sendRequest('../data/users.json');
        const result = response.sort(function() { 
            return .5 - Math.random() 
        }).slice(0, 1);
  
        const responseComments = await sendRequest('https://gorillamediallc.com/bangbase/stored/data/comments_list.json');
        const filterComments = responseComments.filter((comment) => comment.type === "profile");
        const resultComments = filterComments.sort(function() {
          return .5 - Math.random()
        }).slice(0, 1);
        
        const random = Math.floor(Math.random() * resultComments[0].comments.length);
        const random_comment = resultComments[0].comments[random];

        const date = Date.now();
  
        setComments((prev) => [...prev, {
            nick: result[0].nick,
            image: result[0].image,
            comment: random_comment,
            date: date
        }]) 
    }


    useEffect(() => {
        const interval = setInterval(() => {
          if(comments.length < 3) getComments()
        }, 2500);
    
        return () => clearInterval(interval);
    }, [comments.length]);

    //feed
    const [feedmShow, setFeedmShow] = useState(false);
    const feedmhandleClose = () => setFeedmShow(false);
    const feedmHandleShow = () => setFeedmShow(true);

    const [posts, setPosts] = useState([]);
    const [postStatus, setPostStatus] = useState();

    const fetchFeed = async () => {
        const response = await sendRequest('https://gorillamediallc.com/bangbase/api/getusersposts');
        const filter = response.user_posts.filter((item) => item.author === local.username)

        setPosts(filter.reverse())
    }

    const postFeed = async (e) => {
        e.preventDefault()

        if(!postStatus) {
            setError('Status field is required!')
            return;
        }

        const filePath = document.querySelector('#file').files[0];
        const reader = new FileReader();
        
        const allowed_types = ["image/jpeg", "image/jpg", "image/png", "image/gif"]

        if(filePath && !allowed_types.includes(filePath.type)){
            setError('File type is not supported!');
            return;
        }

        const fileSize = filePath?.size / 1024 ** 2; 
        if (filePath && fileSize > 1) {
          setError('File size exceeds 1 MB!');
          return;
        }
        
        if(filePath){
            try {
                reader.addEventListener("load", async function () {    
                    try {
                        await sendRequest(
                            'https://gorillamediallc.com/bangbase/api/poststatus',
                            'POST',
                            JSON.stringify({
                              id: Math.floor(Math.random() * 999999) + 1,
                              author: local.username,
                              status: postStatus,
                              file: reader.result,
                              file_type: filePath?.type,
                              date: Date.now(),
                              comments: []
                            }),
                            {
                              'Content-Type': 'application/json'
                            }
                        );
            
                        feedmhandleClose()
                        fetchFeed()
                    } catch (err) {
                        setError(err);
                        return;
                    }
                }, false);            
                
                if (filePath) {
                    reader.readAsDataURL(filePath);
                }

            } catch (err) {
                console.log(err)
            }

        } else {
            try {
                await sendRequest(
                    'https://gorillamediallc.com/bangbase/api/poststatus',
                    'POST',
                    JSON.stringify({
                      id: Math.floor(Math.random() * 999999) + 1,
                      author: local.username,
                      status: postStatus,
                      date: Date.now(),
                      comments: []
                    }),
                    {
                      'Content-Type': 'application/json'
                    }
                );
    
                feedmhandleClose()
                fetchFeed()
            } catch (err) {
                setError(err);
                return;
            }
        }
        
    }
    
    useEffect(() => {
        fetchFeed()
    }, []);

    const createComment = async (req, res, next) => {
        const response = await sendRequest('../data/users.json');
        const result = response.sort(function() { 
            return .5 - Math.random() 
        }).slice(0, 1);
  
        const responseComments = await sendRequest('https://gorillamediallc.com/bangbase/stored/data/comments_list.json');
        const filterComments = responseComments.filter((comment) => comment.type === "profile");
        const resultComments = filterComments.sort(function() {
          return .5 - Math.random()
        }).slice(0, 1);
        
        const random = Math.floor(Math.random() * resultComments[0].comments.length);
        const random_comment = resultComments[0].comments[random];

        const responsePosts = await sendRequest('https://gorillamediallc.com/bangbase/api/getusersposts');
        const resultPosts = responsePosts.user_posts.sort(function() { 
            return .5 - Math.random() 
        }).slice(0, 1);

        const date = Date.now();     

        try {
            await sendRequest(
                'https://gorillamediallc.com/bangbase/api/autocomm',
                'PATCH',
                JSON.stringify({
                id: resultPosts[0]?.id,
                nick: result[0],
                comment: random_comment,
                date: date,
                }),
                {
                'Content-Type': 'application/json'
                }
            );

            fetchFeed()
        } catch (err) {
            console.log(err)
        }

    }

    useEffect(() => {
        const interval = setInterval(() => {
          if(posts.length > 0) createComment()
        }, 5000);
    
        return () => clearInterval(interval);
    }, [posts.length]);

    return (
        <>
        <div className="dashboard-right">
            {loading && <LoadingSpinner asOverlay />}
    
            <Button variant="dark" className="db-sign" onClick={auth.logout}> <i className="fa-solid fa-xmark"></i> Logout </Button>

            <h3 className="db-title"><i className="fa-solid fa-cog"></i> My Profile</h3> <hr />

            {!loading && userData && (
                <>
                <Modal show={usernameshow} onHide={usernamehandleClose} centered className="custom-modalcontent">
                    <Modal.Body className="position-relative">
                        <Row className="g-0">
                            <Col sm={12} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/modal-image.jpg)`, backgroundSize: 'cover', height: "500px", padding: "0px" }}>
                                <center> <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ height: '50px' }} alt="small logo" className="mt-4" /> </center>

                                <div className="dash-modal">
                                <h1>Edit username</h1>

                                <Form onSubmit={editUsername}>
                                <Form.Group className="mb-3" controlId="formBasicUsername">
                                    <Form.Label>Username:</Form.Label>
                                    <Form.Control type="text" name="username" autoComplete="off" value={username} placeholder="Enter username" onChange={(e) => setUsername(e.target.value)} />
                                    <Form.Text id="ageHelpBlock" muted>
                                        (This will appear on your profile, no spaces)
                                    </Form.Text>
                                </Form.Group>   
                                <Button type="submit" variant="dark"> SAVE </Button>   
                                </Form>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>

                    {error ? (
                        <>
                        <div className="ctm-error"> <i className="fa-solid fa-triangle-exclamation"></i> {error} </div>
                        </>
                    ) : ''}
                </Modal>

                <Modal show={ageshow} onHide={agehandleClose} centered className="custom-modalcontent">
                    <Modal.Body className="position-relative">
                        <Row className="g-0">
                            <Col sm={12} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/modal-image.jpg)`, backgroundSize: 'cover', height: "500px", padding: "0px" }}>
                                <center> <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ height: '50px' }} alt="small logo" className="mt-4" /> </center>

                                <div className="dash-modal">
                                <h1>Edit age</h1>

                                <Form onSubmit={editAge}>
                                <Form.Group className="mb-3" controlId="formBasicAge">
                                    <Form.Label>Age:</Form.Label>
                                    <Form.Control type="text" name="age" maxLength="2" autoComplete="off" value={age} placeholder="Enter age" onChange={(e) => setAge(parseInt(e.target.value))} />
                                    <Form.Text id="ageHelpBlock" muted>
                                        Example: 18, 19, etc. You must be 18 or older.
                                    </Form.Text>
                                </Form.Group>     
                                <Button type="submit" variant="dark"> SAVE </Button>   
                                </Form>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>

                    {error ? (
                        <>
                        <div className="ctm-error"> <i className="fa-solid fa-triangle-exclamation"></i> {error} </div>
                        </>
                    ) : ''}
                </Modal>

                <Modal show={aboutshow} onHide={abouthandleClose} centered className="custom-modalcontent">
                    <Modal.Body className="position-relative">
                        <Row className="g-0">
                            <Col sm={12} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/modal-image.jpg)`, backgroundSize: 'cover', height: "500px", padding: "0px" }}>
                                <center> <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ height: '50px' }} alt="small logo" className="mt-4" /> </center>

                                <div className="dash-modal">
                                <h1>Edit about</h1>

                                <Form onSubmit={editAbout}>
                                <Form.Group className="mb-3" controlId="formBasicAbout">
                                    <Form.Label>About:</Form.Label>
                                    <Form.Control as="textarea" rows={5} name="about" value={about} placeholder="Enter about description" onChange={(e) => setAbout(e.target.value)} />
                                </Form.Group>     
                                <Button type="submit" variant="dark"> SAVE </Button>   
                                </Form>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>

                    {error ? (
                        <>
                        <div className="ctm-error"> <i className="fa-solid fa-triangle-exclamation"></i> {error} </div>
                        </>
                    ) : ''}
                </Modal>

                <Modal show={imageshow} onHide={imagehandleClose} centered className="custom-modalcontent">
                    <Modal.Body className="position-relative">
                        <Row className="g-0">
                            <Col sm={12} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/modal-image.jpg)`, backgroundSize: 'cover', height: "500px", padding: "0px" }}>
                                <center> <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ height: '50px' }} alt="small logo" className="mt-4" /> </center>

                                <div className="dash-modal">
                                <h1>Edit Image</h1>

                                <Form onSubmit={editImage}>
                                <Form.Group className="mb-3">
                                        <Form.Label>Profile picture</Form.Label>
                                        <Form.Control type="file" id="image" />
                                </Form.Group>                                   
                                <Button type="submit" variant="dark"> SAVE </Button>   
                                </Form>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    </Modal>

                    <Modal show={feedmShow} onHide={feedmhandleClose} centered className="custom-modalcontent">
                    <Modal.Body className="position-relative">
                        <Row className="g-0">
                            <Col sm={12} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/modal-image.jpg)`, backgroundSize: 'cover', height: "500px", padding: "0px" }}>
                                <center> <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ height: '50px' }} alt="small logo" className="mt-4" /> </center>

                                <div className="dash-modal">
                                <h1>Post</h1>

                                <Form onSubmit={postFeed}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextareaStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control onChange={(e) => setPostStatus(e.target.value)} as="textarea" rows={3} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                        <Form.Label>Upload photo</Form.Label>
                                        <Form.Control type="file" id="file" />
                                        <Form.Text muted>
                                            Allowed types: jpg, jpeg, png, gif
                                            <p>Maximum size: <b>1 MB</b></p>
                                        </Form.Text>
                                </Form.Group>                                   
                                <Button type="submit" variant="dark"> POST </Button>   
                                </Form>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>

                    {error ? (
                        <>
                        <div className="ctm-error"> <i className="fa-solid fa-triangle-exclamation"></i> {error} </div>
                        </>
                    ) : ''}
                </Modal>

                <Row data-aos="fade-down">
                    <Col sm={5}> 
                        <div className="profileavatar position-relative">
                        <Button variant="dark" onClick={imageHandleShow} size="sm" className="edit-photo-btn"> <i className="fa-solid fa-pencil"></i> Edit picture </Button>
                        {image ? (
                            <>
                                <img src={image} className="img-thumbnail img-responsive w-100 h-100" style={{ maxHeight: "200px" }} alt={userData.username} />
                            </>
                        ) : (
                            <>
                                 <img src={`${process.env.PUBLIC_URL}/images/default_avatar.jpg`} style={{ maxHeight: "200px" }} className="img-thumbnail img-responsive w-100 h-100" alt={userData.username} />                           
                            </>
                        )}
                        </div>
                    </Col>
                    <Col sm={7}> 
                        <div className="profile-infoblock">
                            <Button variant="dark" onClick={usernameHandleShow} className="float-end mt-2"> <i className="fa-solid fa-pencil"></i> </Button>

                            <label>Username:</label>
                            <div>{userData.username}</div>
                        </div>

                        <div className="profile-infoblock">
                            <Button variant="dark" className="float-end mt-2" disabled> <i className="fa-solid fa-lock"></i> </Button>

                            <label>Email:</label>
                            <div>{userData.email}</div>
                        </div>

                        <div className="profile-infoblock">
                            <Button variant="dark" onClick={ageHandleShow} className="float-end mt-2"> <i className="fa-solid fa-pencil"></i> </Button>

                            <label>Age:</label>
                            <div>{userData.years}</div>
                        </div>
                    </Col>
                </Row>

                 <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    >
                        <Tab eventKey="profile" title="Profile">
                                    <Row data-aos="fade-down" className="m-2 general-visitor-block">
                                    <h3 className="db-title"> Comments </h3> <small> List of comments.</small> <hr />
                                    {comments && comments.length < 1 && (
                                                <>
                                                <p>No results!</p>
                                                </>
                                    )}
                                    {comments && comments.length > 0 && comments.map((c, index) => {                        
                                        return (
                                            <React.Fragment key={`c` + index}>
                                                <Col sm={12} className="general-block-comments">
                                                    <Link to={`/dashboard/member/${c.nick}`}>
                                                    <Row className="comment-row-profile" data-aos="fade-down">
                                                        <Col sm={2} className="comment-row-profile-image">
                                                            <img src={c.image} className="img-fluid img-thumbnail" />
                                                        </Col>
                                                        <Col sm={10} className="position-relative">
                                                            <b>{c.nick}</b>
                                                            <p>{c.comment}</p>
                                                            <small>{new Date(c.date).toDateString()}</small>
                                                        </Col>
                                                    </Row>
                                                    </Link>
                                                </Col>
                                            </React.Fragment>
                                        )
                                    })}
                                </Row>

                                <Row data-aos="fade-down" className="m-2 general-visitor-block">
                                <h3 className="db-title"> Visitors </h3> <small>List of last visitors.</small> <hr />
                                {profileV && profileV.length < 1 && (
                                    <>
                                    <p>No results!</p>
                                    </>
                                )}

                                {profileV && profileV.map((plv, index) => {
                                    return (
                                        <React.Fragment key={`plv` + index}>
                                            <Col sm={6}>
                                            <Link to={`/dashboard/member/${plv.content.nick}`}>
                                                <Row className="visitor-block" data-aos="fade-down">
                                                    <Col sm={5}>
                                                        <div className="visitor-block-image">
                                                            <img src={plv.content.image} className="img-fluid img-thumbnail" />
                                                        </div>
                                                    </Col>
                                                    <Col sm={7}>
                                                        <h4>{plv.content.nick}</h4>
                                                        <span> ONLINE </span>
                                                    </Col>
                                                </Row>
                                            </Link>
                                            </Col>
                                        </React.Fragment>
                                    )
                                })}
                                </Row>

                                <Row data-aos="fade-down" className="m-2">
                                <Col sm={12}>
                                <Button variant="dark" onClick={aboutHandleShow} className="float-end mt-4"> <i className="fa-solid fa-pencil"></i> </Button>
                                <h3 className="db-title"> about </h3> <small>Tell us a little more about yourself.</small> <hr />
                                {userData.about ? (
                                    <>
                                    <p>{userData.about}</p>
                                    </>
                                ) : (
                                    <>
                                    <p>No results!</p>
                                    </>
                                )}
                                </Col>
                                </Row>                            
                        </Tab>
                        <Tab eventKey="feed" title="Gallery">
                                <Button variant="dark" onClick={feedmHandleShow} className="float-end mt-2"> <i className="fa-solid fa-plus"></i> Upload image </Button>
                                <h3 className="db-title"> Gallery </h3> <small> List of your posts.</small> <hr />

                                {posts && posts.length < 1 && (
                                    <>
                                    <p>No results!</p>
                                    </>
                                )}

                                {posts && posts.map((p, index) => {
                                    return (
                                        <React.Fragment key={`p` + index}>
                                            <div className="post-body">
                                                <Row>
                                                    <Col sm={6}>
                                                    <b>{p.author}</b> <br />
                                                    <small>{new Date(p.date).toDateString()}</small>

                                                    <hr />

                                                    <span>{p.status}</span> <div className="space10px"></div>
                                                    {p.file_type && p.file && (
                                                        <>
                                                        <div className="post-image">
                                                            <img src={p.file} className="img-fluid" style={{ width: "100%", height: "100%", objectFit: 'cover' }} />
                                                        </div>
                                                        </>
                                                    )}
                                                    </Col>
                                                    <Col sm={6}>
                                                    <b>Comments</b> <br />
                                                    <small>List of latest comments.</small> <hr />

                                                    {p.comments && p.comments.length < 1 && (
                                                        <>
                                                        <p>No results!</p>
                                                        </>
                                                    )}

                                                    <div className="addbordercontent-comments">
                                                    {p.comments && p.comments.map((pc, index) => {
                                                        return (
                                                            <React.Fragment key={`pc` + index}>
                                                                <Row className="comments-profile-row">
                                                                    <Col sm={4}>
                                                                        <div className="profile-c-img">
                                                                        <img src={pc.nick.image} className="img-flud" />
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={8}>
                                                                        <Link to={`/dashboard/member/${pc.nick.nick}`}>{pc.nick.nick}</Link> <br /> <span>{pc.comment}</span>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                    </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                        </Tab>
                </Tabs>
                </>
            )}
        </div>
        </>
    )
}

export default DashboardProfile;