import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Container, Row, Col, Button } from 'react-bootstrap';

import IframeModal from './helpers/IframeModal';
import CustomModal from './helpers/CustomModal';

import axios from 'axios';

const DisplayAds = prop => {
    const [topAds, setTopAds] = useState();
    const [leftAds, setLeftAds] = useState();
    const [rightAds, setRightAds] = useState();
    const [bottomAds, setBottomAds] = useState();

    const [iframeBtn, setIframeBtn] = useState(false);

    const [customBtn, setCustomBtn] = useState(false);
    const [customBtnType, setCustomBtnType] = useState();   


    const queryStringLink = window.location.search;
    const urlParamsLink = new URLSearchParams(queryStringLink);

    urlParamsLink.get("uid") || localStorage.getItem('uid')

    const transaction_id = urlParamsLink.get("aff_sub") || localStorage.getItem('transaction_id')
    const uid = urlParamsLink.get("uid") || localStorage.getItem('uid')
    const aff_sub1 = urlParamsLink.get("aff_sub1") || localStorage.getItem('aff_sub1')
    const aff_sub2 = urlParamsLink.get("aff_sub2") || localStorage.getItem('aff_sub2')
    const aff_sub3 = urlParamsLink.get("aff_sub3") || localStorage.getItem('aff_sub3')
    const aff_sub4 = urlParamsLink.get("aff_sub4") || localStorage.getItem('aff_sub4')
    const aff_sub5 = urlParamsLink.get("aff_sub5") || localStorage.getItem('aff_sub5')

    const user = localStorage.getItem('user')
    const userInfo = user && JSON.parse(user)

    const localEmailSystem = localStorage.getItem('emailsystem')
    const localCplSystem = localStorage.getItem('cplsystem')

    const link = `?sub1=${aff_sub1}&sub2=${aff_sub2}&sub3=${aff_sub3}&sub5=${aff_sub5}&transaction_id=${transaction_id}`;
    const emailsystemlink = `&visitor_id=${aff_sub1}&SUBID=${aff_sub2}&email=${aff_sub4}&AFFID=${aff_sub5}&transaction_id=${transaction_id}`
    const staticURL = `https://www.gcdates.com/3J67C/28NT2W5/?sub1=${transaction_id}&sub2=${aff_sub2}&sub3=${aff_sub3}&sub4=${aff_sub4}&sub5=${aff_sub5}`

    const displayLink = localEmailSystem === 'on' ? emailsystemlink : link

    const fetchAds = async () => {
        try {
            const responseData = await axios.get('https://gorillamediallc.com/bangbase/stored/data/ads_list.json');
            const maxData = await axios.get('https://gorillamediallc.com/bangbase/stored/data/settings.json');

            const maxTop = maxData.data.settings.maxTop;
            const maxLeft = maxData.data.settings.maxLeft;
            const maxRight = maxData.data.settings.maxRight;
            const maxBottom = maxData.data.settings.maxBottom;

            const filterTop = responseData.data.filter((ad) => ad.type === 1);
            const resultTop = filterTop.sort(function() { 
                return .5 - Math.random() 
            }).slice(0, maxTop);
            setTopAds(resultTop)

            const filterLeft = responseData.data.filter((ad) => ad.type === 2);
            const resultLeft = filterLeft.sort(function() { 
                return .5 - Math.random() 
            }).slice(0, maxLeft);
            setLeftAds(resultLeft)

            const filterRight = responseData.data.filter((ad) => ad.type === 3);
            const resultRight = filterRight.sort(function() { 
                return .5 - Math.random() 
            }).slice(0,maxRight);
            setRightAds(resultRight)

            const filterBottom = responseData.data.filter((ad) => ad.type === 4);
            const resultBottom = filterBottom.sort(function() { 
                return .5 - Math.random() 
            }).slice(0, maxBottom);
            setBottomAds(resultBottom)
            
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchAds()
    }, [prop])

    // counter
    const [countNumber, setCountNumber] = useState(0)
    const [affPercent, setAffPercent] = useState(8) // < 9

    const clickedAd = () => {
        setCountNumber(countNumber + 1)
    }

    useEffect(() => {
        if(countNumber >= 10) setCountNumber(0)
    }, [countNumber]);


    // adserver parse function
    function fixAdserver(adlink, type) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(adlink, 'text/html');
        const insElement = doc.querySelector('iframe');
    
        if (insElement) {
            insElement.setAttribute('src', insElement.getAttribute('src') + "&source=" + aff_sub2);
            return doc.body.innerHTML;
        }
    
        return adlink;
    }
      

    const [userGeo, setUserGeo] = useState();

    const getGeoInfo = async () => {
        try {
            const response = await axios.get('https://geolocation-db.com/json/');
            setUserGeo(response.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getGeoInfo()
    }, []);

    const formatGeoLinks = (currentlink, ad, type, currentcountry) => {
        const getLink = ad.countries.find((c) => c.code?.toUpperCase() === currentcountry?.toUpperCase() && c.id === type);

        let result;
        if(getLink){
            result = getLink.link
        } else {
            result = currentlink;
        }

        return result;
    }
    
    return (
        <>
        <IframeModal display={iframeBtn} onClose={() => {
            setIframeBtn(false)
            localStorage.setItem('iframeactive', false)
        }} />

        <CustomModal display_custom={customBtn} type={customBtnType} onClose={() => {
            setCustomBtn(false)
            setCustomBtnType(null)
        }} />       

        {prop.position === "dashboard_rightsidebar" ? (
                <>
                   {rightAds && rightAds.map((ad, index) => {
                            let adlink = ad.redirection;
                            let adtype = 1;
                            // let adlink;
                            // let adtype;

                            // if(ad.emailsystem === true && localEmailSystem === 'on'){
                            //     if(userInfo.years < 30){
                            //         adlink = formatGeoLinks(ad.emaillink, ad, 'EmailLinkDefault', userGeo?.country_code)
                            //         adtype = 2
                            //     } else if(userInfo.years > 30){
                            //         adlink = formatGeoLinks(ad.emaillinkolder, ad, 'EmailLinkOlder', userGeo?.country_code)
                            //         adtype = 2
                            //     }
                            // } else if(ad.cplsystem === true && localCplSystem === 'on'){
                            //     adlink = formatGeoLinks(ad.cpllink, ad, 'CplSystemLink', userGeo?.country_code)
                            //     adtype = 1
                            // } else {
                            //     if(ad.agesystem === true){
                    
                            //         if(userInfo.years > 30){
                            //             adlink = formatGeoLinks(ad.agelink, ad, 'AgeSystemLink', userGeo?.country_code)
                            //             adtype = 1
                            //         }  else {
                            //             adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
                            //             adtype = 1
                            //         }
                    
                            //     } else if(ad.split === true) {
                    
                            //         if(affPercent > countNumber){
                            //             adlink = formatGeoLinks(ad.redirection_aff, ad, 'LinkAff', userGeo?.country_code)
                            //             adtype = 1
                            //         } else {
                            //             adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
                            //             adtype = 1
                            //         }
                    
                            //     } else {
                            //         adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
                            //         adtype = 1
                            //     }
                            // }

                            return (
                                <React.Fragment key={'top' + index}>
                                <Col sm={12}>
                                    {ad.adserver === true ? (
                                        <>
                                        <div dangerouslySetInnerHTML={{ __html: fixAdserver(adlink, adtype) }} />
                                        </>
                                    ) : (
                                        <>
                                        {/* <a href={adlink + displayLink} rel="noreferrer" target="_blank" onClick={() => clickedAd()}> */}
                                        <a href={staticURL} rel="noreferrer" target="_blank" onClick={() => clickedAd()}>
                                        <img className="img-fluid m-2 mx-auto d-block" src={ad.image}  style={{maxHeight: "390px"}} />                                    
                                        </a>
                                        </>
                                    )}
                                </Col>
                                </React.Fragment>
                            )

                   })}
                </>
        ) : prop.position === "dashboard_contenttop" ? (
                <>
                    <Row className="hide-nativeadsmobile">
                        {topAds && topAds.map((ad, index) => {
                            let adlink = ad.redirection;
                            let adtype = 1;
                            // let adlink;
                            // let adtype;

                            // if(ad.emailsystem === true && localEmailSystem === 'on'){
                            //     if(userInfo.years < 30){
                            //         adlink = formatGeoLinks(ad.emaillink, ad, 'EmailLinkDefault', userGeo?.country_code)
                            //         adtype = 2
                            //     } else if(userInfo.years > 30){
                            //         adlink = formatGeoLinks(ad.emaillinkolder, ad, 'EmailLinkOlder', userGeo?.country_code)
                            //         adtype = 2
                            //     }
                            // } else if(ad.cplsystem === true && localCplSystem === 'on'){
                            //     adlink = formatGeoLinks(ad.cpllink, ad, 'CplSystemLink', userGeo?.country_code)
                            //     adtype = 1
                            // } else {
                            //     if(ad.agesystem === true){
                    
                            //         if(userInfo.years > 30){
                            //             adlink = formatGeoLinks(ad.agelink, ad, 'AgeSystemLink', userGeo?.country_code)
                            //             adtype = 1
                            //         }  else {
                            //             adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
                            //             adtype = 1
                            //         }
                    
                            //     } else if(ad.split === true) {
                    
                            //         if(affPercent > countNumber){
                            //             adlink = formatGeoLinks(ad.redirection_aff, ad, 'LinkAff', userGeo?.country_code)
                            //             adtype = 1
                            //         } else {
                            //             adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
                            //             adtype = 1
                            //         }
                    
                            //     } else {
                            //         adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
                            //         adtype = 1
                            //     }
                            // }

                            return (
                                <React.Fragment key={'top' + index}>
                                <Col sm={12}>
                                    {ad.adserver === true ? (
                                        <>
                                        <div dangerouslySetInnerHTML={{ __html: fixAdserver(adlink, adtype) }} />
                                        </>
                                    ) : (
                                        <>
                                        {/* <a href={adlink + displayLink} rel="noreferrer" target="_blank" onClick={() => clickedAd()}> */}
                                        <a href={staticURL} rel="noreferrer" target="_blank" onClick={() => clickedAd()}>
                                        <img className="img-fluid m-2 mx-auto d-block" src={ad.image}  style={{maxHeight: "390px"}} />                                    
                                        </a>
                                        </>
                                    )}
                                </Col>
                                </React.Fragment>
                            )
                        })}
                    </Row>
                </>
        ) : prop.position === "dashboard_contentbottom" ? (
            <>
                <Row>
                    {bottomAds && bottomAds.map((ad, index) => {
                            let adlink = ad.redirection;
                            let adtype = 1;
                            // let adlink;
                            // let adtype;

                            // if(ad.emailsystem === true && localEmailSystem === 'on'){
                            //     if(userInfo.years < 30){
                            //         adlink = formatGeoLinks(ad.emaillink, ad, 'EmailLinkDefault', userGeo?.country_code)
                            //         adtype = 2
                            //     } else if(userInfo.years > 30){
                            //         adlink = formatGeoLinks(ad.emaillinkolder, ad, 'EmailLinkOlder', userGeo?.country_code)
                            //         adtype = 2
                            //     }
                            // } else if(ad.cplsystem === true && localCplSystem === 'on'){
                            //     adlink = formatGeoLinks(ad.cpllink, ad, 'CplSystemLink', userGeo?.country_code)
                            //     adtype = 1
                            // } else {
                            //     if(ad.agesystem === true){
                    
                            //         if(userInfo.years > 30){
                            //             adlink = formatGeoLinks(ad.agelink, ad, 'AgeSystemLink', userGeo?.country_code)
                            //             adtype = 1
                            //         }  else {
                            //             adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
                            //             adtype = 1
                            //         }
                    
                            //     } else if(ad.split === true) {
                    
                            //         if(affPercent > countNumber){
                            //             adlink = formatGeoLinks(ad.redirection_aff, ad, 'LinkAff', userGeo?.country_code)
                            //             adtype = 1
                            //         } else {
                            //             adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
                            //             adtype = 1
                            //         }
                    
                            //     } else {
                            //         adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
                            //         adtype = 1
                            //     }
                            // }

                            return (
                                <React.Fragment key={'top' + index}>
                                <Col sm={6}>
                                    {ad.adserver === true ? (
                                        <>
                                        <center><div dangerouslySetInnerHTML={{ __html: fixAdserver(adlink, adtype) }} /></center>
                                        </>
                                    ) : (
                                        <>
                                        {/* <a href={adlink + displayLink} rel="noreferrer" target="_blank" onClick={() => clickedAd()}> */}
                                        <a href={staticURL} rel="noreferrer" target="_blank" onClick={() => clickedAd()}>
                                        <img className="img-fluid m-2 mx-auto d-block" src={ad.image}  style={{maxHeight: "390px"}} />                                    
                                        </a>
                                        </>
                                    )}
                                </Col>
                                </React.Fragment>
                            )

                    })}
                </Row>
            </>
    ) : prop.position === "dashboard_leftsidebar_bottom" ? (
           <>
                   {leftAds && leftAds.map((ad, index) => {
                        let adlink = ad.redirection;
                        let adtype = 1;
                        // let adlink;
                        // let adtype;

                        // if(ad.emailsystem === true && localEmailSystem === 'on'){
                        //     if(userInfo.years < 30){
                        //         adlink = formatGeoLinks(ad.emaillink, ad, 'EmailLinkDefault', userGeo?.country_code)
                        //         adtype = 2
                        //     } else if(userInfo.years > 30){
                        //         adlink = formatGeoLinks(ad.emaillinkolder, ad, 'EmailLinkOlder', userGeo?.country_code)
                        //         adtype = 2
                        //     }
                        // } else if(ad.cplsystem === true && localCplSystem === 'on'){
                        //     adlink = formatGeoLinks(ad.cpllink, ad, 'CplSystemLink', userGeo?.country_code)
                        //     adtype = 1
                        // } else {
                        //     if(ad.agesystem === true){

                        //         if(userInfo.years > 30){
                        //             adlink = formatGeoLinks(ad.agelink, ad, 'AgeSystemLink', userGeo?.country_code)
                        //             adtype = 1
                        //         }  else {
                        //             adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
                        //             adtype = 1
                        //         }

                        //     } else if(ad.split === true) {

                        //         if(affPercent > countNumber){
                        //             adlink = formatGeoLinks(ad.redirection_aff, ad, 'LinkAff', userGeo?.country_code)
                        //             adtype = 1
                        //         } else {
                        //             adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
                        //             adtype = 1
                        //         }

                        //     } else {
                        //         adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
                        //         adtype = 1
                        //     }
                        // }

                        return (
                            <React.Fragment key={'top' + index}>
                            <Col sm={12}>
                                {ad.adserver === true ? (
                                    <>
                                    <center><div dangerouslySetInnerHTML={{ __html: fixAdserver(adlink, adtype) }} /></center>
                                    </>
                                ) : (
                                    <>
                                    {/* <a href={adlink + displayLink} rel="noreferrer" target="_blank" onClick={() => clickedAd()}> */}
                                    <a href={staticURL} rel="noreferrer" target="_blank" onClick={() => clickedAd()}>
                                    <img className="img-fluid m-2 mx-auto d-block" src={ad.image}  style={{maxHeight: "390px"}} />                                    
                                    </a>
                                    </>
                                )}
                            </Col>
                            </React.Fragment>
                        )

                   })}
           </>
    ) : null}
        </>
    )
}

export default DisplayAds;